import gql from 'graphql-tag';
import {
  FILE_ALL_FIELDS,
  MEDIA_UNION,
  REAL_ESTATE_ALL_FIELDS_FRAGMENT,
  REAL_ESTATE_ALL_FIELDS_FRAGMENT_BASIC,
  REAL_ESTATE_ALL_FIELDS_FRAGMENT_FOR_PUBLISH,
  REAL_ESTATE_ALL_FIELDS_V2_FRAGMENT,
  REAL_ESTATE_PROJECT_ALL_FIELDS_V2_FRAGMENT,
} from '@schemas/fragments';

// export const GET_REAL_ESTATE = gql`
//   query getRealEstates(
//     $creator: String
//     $searchString: String
//     $typeOfDemand: String
//     $kindOfRealEstate: [String]
//     $realEstateType: [String]
//     $province: String
//     $district: String
//     $ward: String
//     $street: String
//     $price: FromTo
//     $area: FromTo
//     $length: FromTo
//     $width: FromTo
//     $rear: FromTo
//     $floorCount: FromTo
//     $bedroomCount: FromTo
//     $toiletCount: FromTo
//     $bedRoomArea: FromTo
//     $floorPosition: FromTo
//     $roomCount: FromTo
//     $blockCount: FromTo
//     $alleyWidth: FromTo
//     $phone: String
//     $direction: [Direction]
//     $sort: JSONObject
//     $isAuthorizedRe: Boolean
//     $isSaleOffRE: Boolean
//     $mediaTypes: [String]
//     $utilities: [String]
//     $hasSeaView: Boolean
//     $hotelStar: Float
//     $hasAlley: Boolean
//     $villaView: [String]
//     $projectName: String
//     $page: Int
//     $limit: Int
//     $postStatus: [PostStatusType]
//     $tradingStatus: [TradingStatus]
//     $postType: [String]
//     $buildingType: String
//     $filterOnMap: MapCoordinates
//     $createdAt: FromToDateInput
//   ) @api(name: realEstate) {
//     getRealEstates(
//       creator: $creator
//       searchString: $searchString
//       kindOfRealEstate: $kindOfRealEstate
//       typeOfDemand: $typeOfDemand
//       realEstateType: $realEstateType
//       province: $province
//       district: $district
//       ward: $ward
//       street: $street
//       price: $price
//       area: $area
//       length: $length
//       width: $width
//       rear: $rear
//       floorCount: $floorCount
//       bedroomCount: $bedroomCount
//       toiletCount: $toiletCount
//       bedRoomArea: $bedRoomArea
//       floorPosition: $floorPosition
//       roomCount: $roomCount
//       blockCount: $blockCount
//       alleyWidth: $alleyWidth
//       phone: $phone
//       direction: $direction
//       sort: $sort
//       isAuthorizedRe: $isAuthorizedRe
//       isSaleOffRE: $isSaleOffRE
//       mediaTypes: $mediaTypes
//       utilities: $utilities
//       hasSeaView: $hasSeaView
//       hotelStar: $hotelStar
//       hasAlley: $hasAlley
//       villaView: $villaView
//       projectName: $projectName
//       page: $page
//       limit: $limit
//       postStatus: $postStatus
//       tradingStatus: $tradingStatus
//       postType: $postType
//       buildingType: $buildingType
//       filterOnMap: $filterOnMap
//       createdAt: $createdAt
//     ) {
//       total
//       items {
//         id
//         fullAddress
//         demandEstate
//         realEstateId
//         realEstateType
//         type
//         isForSell
//         width
//         length
//         rear
//         area
//         floorCount
//         price
//         priceUnit
//         bedroomCount
//         toiletCount
//         direction
//         postTitle
//         legals
//         mediaUrlsIds
//         mediaUrls {
//           ... on FileWithFullUrls {
//             type
//             originalUrl
//             urls {
//               type
//               url
//             }
//           }
//         }
//         postType
//         startShowTime
//         endShowTime
//         tradingStatus
//         postStatus
//         isSaved
//         isAuthorizedRe
//         isSaleOffRE
//         createdAt
//         saveItem {
//           id
//           note
//           belongsToCollection {
//             id
//           }
//         }
//       }
//       limit
//       page
//       nextPage
//       prevPage
//       totalPages
//       pagingCounter
//       hasNextPage
//       hasPrevPage
//     }
//   }
// `;
export const GET_SUGGEST_REAL_ESTATE = gql`
  query getSuggestRealEstate($searchString: String!) @api(name: realEstate) {
    getSuggestRealEstate(searchString: $searchString) {
      showString
      searchString
      resultType
      matchedItems
      totalItems
    }
  }
`;

// export const GET_REAL_ESTATE_PROJECT = gql`
//   query getRealEstateProjects(
//     $realEstateType: [String]
//     $searchString: String
//     $province: String
//     $district: String
//     $ward: String
//     $street: String
//     $price: FromTo
//     $area: FromTo
//     $floorCount: FromTo
//     $utilities: [String]
//     $createdDate: FromToDateInput
//     $apartmentCount: FromTo
//     $progress: String
//     $hasAlley: Boolean
//     $totalLandArea: FromTo
//     $page: Int
//     $limit: Int
//     $sort: JSONObject
//   ) @api(name: realEstate) {
//     getRealEstateProjects(
//       realEstateType: $realEstateType
//       searchString: $searchString
//       province: $province
//       district: $district
//       ward: $ward
//       street: $street
//       price: $price
//       area: $area
//       floorCount: $floorCount
//       utilities: $utilities
//       createdDate: $createdDate
//       apartmentCount: $apartmentCount
//       progress: $progress
//       hasAlley: $hasAlley
//       totalLandArea: $totalLandArea
//       page: $page
//       limit: $limit
//       sort: $sort
//     ) {
//       total
//       items {
//         id
//         ...RealEstateProjectAllFieldsV2
//       }
//       limit
//       page
//       nextPage
//       prevPage
//       totalPages
//       pagingCounter
//       hasNextPage
//       hasPrevPage
//     }
//   }
//   ${REAL_ESTATE_PROJECT_ALL_FIELDS_V2_FRAGMENT}
// `;

export const GET_REAL_ESTATE_PROJECT_BY_FIELD = (field = []) => gql`
  query getRealEstateProjects(
    $realEstateType: [String]
    $searchString: String
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $floorCount: FromTo
    $utilities: [String]
    $createdDate: FromToDateInput
    $apartmentCount: FromTo
    $progress: String
    $hasAlley: Boolean
    $totalLandArea: FromTo
    $page: Int
    $limit: Int
    $sort: JSONObject
  ) @api(name: realEstate) {
    getRealEstateProjects(
      realEstateType: $realEstateType
      searchString: $searchString
      province: $province
      district: $district
      ward: $ward
      street: $street
      price: $price
      area: $area
      floorCount: $floorCount
      utilities: $utilities
      createdDate: $createdDate
      apartmentCount: $apartmentCount
      progress: $progress
      hasAlley: $hasAlley
      totalLandArea: $totalLandArea
      page: $page
      limit: $limit
      sort: $sort
    ) {
      total
      items {
        id
        ${field.join()}
      }
      limit
      page
      totalPages
      hasNextPage
    }
  }
  ${field.find((e) => e.includes('FileAllFields')) ? FILE_ALL_FIELDS : ''}
`;

export const GET_REAL_ESTATE_BY_FIELD = (field = []) => gql`
  query getRealEstates(
    $creator: String
    $searchString: String
    $typeOfDemand: String
    $kindOfRealEstate: [String]
    $realEstateType: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $bedRoomArea: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $blockCount: FromTo
    $alleyWidth: FromTo
    $phone: String
    $direction: [Direction]
    $sort: JSONObject
    $isAuthorizedRe: Boolean
    $isSaleOffRE: Boolean
    $mediaTypes: [String]
    $utilities: [String]
    $hasSeaView: Boolean
    $hotelStar: Float
    $hasAlley: Boolean
    $villaView: [String]
    $projectName: String
    $page: Int
    $limit: Int
    $postStatus: [PostStatusType]
    $tradingStatus: [TradingStatus]
    $postType: [String]
    $buildingType: String
    $filterOnMap: MapCoordinates
    $createdAt: FromToDateInput
    $ids: [ObjectID]
    $approvalStatus: ApprovalStatusEnumType
    $startShowTime: FromTo
    $except:ObjectID
  ) @api(name: realEstate) {
    getRealEstates(
      ids: $ids
      creator: $creator
      searchString: $searchString
      kindOfRealEstate: $kindOfRealEstate
      typeOfDemand: $typeOfDemand
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
      street: $street
      price: $price
      area: $area
      length: $length
      width: $width
      rear: $rear
      floorCount: $floorCount
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      bedRoomArea: $bedRoomArea
      floorPosition: $floorPosition
      roomCount: $roomCount
      blockCount: $blockCount
      alleyWidth: $alleyWidth
      phone: $phone
      direction: $direction
      sort: $sort
      isAuthorizedRe: $isAuthorizedRe
      isSaleOffRE: $isSaleOffRE
      mediaTypes: $mediaTypes
      utilities: $utilities
      hasSeaView: $hasSeaView
      hotelStar: $hotelStar
      hasAlley: $hasAlley
      villaView: $villaView
      projectName: $projectName
      page: $page
      limit: $limit
      postStatus: $postStatus
      tradingStatus: $tradingStatus
      postType: $postType
      buildingType: $buildingType
      filterOnMap: $filterOnMap
      createdAt: $createdAt
      approvalStatus: $approvalStatus
      startShowTime: $startShowTime
      except:$except
    ) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        ${field.join()}
      }
      
    }
  }
  ${field.find((e) => e.includes('FileAllFields2')) ? MEDIA_UNION : ''}
`;

export const GET_NEED_REAL_ESTATE_BY_FIELD = (field = []) => gql`
  query getNeededRealEstates(
    $typeOfDemand: String
    $creator: String
    $realEstateType: [String]
    $searchString: String
    $postStatus: [PostStatusType]
    $tradingStatus: [TradingStatus]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $priceUnit: String
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $bedRoomArea: FromTo
    $floorCount: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $blockCount: FromTo
    $projectName: String
    $hasSeaView: Boolean
    $villaView: [String]
    $hotelStar: Float
    $investor: String
    $buildingType: String
    $direction: [String]
    $page: Int
    $limit: Int
    $sort: JSONObject
    $mediaTypes: [String]
    $approvalStatus: ApprovalStatusEnumType
    $startShowTime: FromTo
    $except :ObjectID
    $postType: [String]
    $utilities: [String]
    $hasAlley:Boolean
  ) @api(name: realEstate) {
    getNeededRealEstates(
      typeOfDemand: $typeOfDemand
      creator: $creator
      realEstateType: $realEstateType
      searchString: $searchString
      postStatus: $postStatus
      tradingStatus: $tradingStatus
      province: $province
      district: $district
      ward: $ward
      street: $street
      price: $price
      priceUnit: $priceUnit
      area: $area
      length: $length
      width: $width
      rear: $rear
      bedRoomArea: $bedRoomArea
      floorCount: $floorCount
      floorPosition: $floorPosition
      roomCount: $roomCount
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      blockCount: $blockCount
      projectName: $projectName
      hasSeaView: $hasSeaView
      villaView: $villaView
      hotelStar: $hotelStar
      investor: $investor
      buildingType: $buildingType
      direction: $direction
      page: $page
      limit: $limit
      sort: $sort
      mediaTypes: $mediaTypes
      approvalStatus: $approvalStatus
      startShowTime: $startShowTime
      except :$except
      postType: $postType
      utilities:$utilities
      hasAlley:$hasAlley
    ) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        ${field.join()}
      }
      
    }
  }
  ${field.find((e) => e.includes('FileAllFields2')) ? MEDIA_UNION : ''}
`;

export const GET_NEAR_REAL_ESTATE = gql`
  query nearRealEstates(
    $isForSell: Boolean
    $kindOfRealEstate: KindRealEstateEnumType
    $realEstateType: [String]
    $province: ObjectID
    $district: ObjectID
  ) @api(name: realEstate) {
    nearRealEstates(
      kindOfRealEstate: $kindOfRealEstate
      isForSell: $isForSell
      realEstateType: $realEstateType
      province: $province
      district: $district
    ) {
      districtId
      districtName
      total
    }
  }
`;

export const NEAR_REAL_ESTATE_BY_PROVINCE = gql`
  query nearRealEstatesByProvince(
    $isForSell: Boolean
    $kindOfRealEstate: KindRealEstateEnumType
    $realEstateType: [String]
    $province: ObjectID
  ) @api(name: realEstate) {
    nearRealEstatesByProvince(
      kindOfRealEstate: $kindOfRealEstate
      isForSell: $isForSell
      realEstateType: $realEstateType
      province: $province
    ) {
      provinceId
      provinceName
      total
    }
  }
`;

export const NEAR_NEEDED_REAL_ESTATE = gql`
  query nearNeededRealEstates(
    $typeOfDemand: String
    $realEstateType: [String]
    $province: ObjectID
    $district: ObjectID
  ) @api(name: realEstate) {
    nearNeededRealEstates(
      typeOfDemand: $typeOfDemand
      realEstateType: $realEstateType
      province: $province
      district: $district
    ) {
      districtId
      districtName
      total
    }
  }
`;

export const NEAR_REAL_ESTATE_BY_WARD = gql`
  query nearRealEstatesByWard(
    $isForSell: Boolean
    $kindOfRealEstate: KindRealEstateEnumType
    $realEstateType: [String]
    $province: ObjectID
    $district: ObjectID
    $ward: ObjectID
  ) @api(name: realEstate) {
    nearRealEstatesByWard(
      isForSell: $isForSell
      kindOfRealEstate: $kindOfRealEstate
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
    ) {
      wardId
      wardName
      total
    }
  }
`;
export const GET_REAL_ESTATE_FEES = gql`
  query getRealEstateFees(
    $id: ObjectID
    $startDate: Date!
    $endDate: Date!
    $postType: Int!
    $actionType: RealEstateActionType
    $type: RealEstateEnumType
  ) @api(name: realEstate) {
    getRealEstateFees(
      id: $id
      startDate: $startDate
      endDate: $endDate
      postType: $postType
      actionType: $actionType
      type: $type
    ) {
      basicFees
      vat
      totalFees
      totalDays
      noChanges
      update
    }
  }
`;

export const GET_REAL_ESTATE_DETAILS = gql`
  query getRealEstateDetails($realEstateId: ObjectID!) @api(name: realEstate) {
    getRealEstateDetails(realEstateId: $realEstateId) {
      ...RealEstateAllFields
    }
  }
  ${REAL_ESTATE_ALL_FIELDS_FRAGMENT}
`;
export const GET_REAL_ESTATE_DETAILS_BASIC = gql`
  query getRealEstateDetails($realEstateId: ObjectID!) @api(name: realEstate) {
    getRealEstateDetails(realEstateId: $realEstateId) {
      ...RealEstateAllFieldsBasic
    }
  }
  ${REAL_ESTATE_ALL_FIELDS_FRAGMENT_BASIC}
`;
export const GET_REAL_ESTATE_DETAILS_FOR_PUBLISH = gql`
  query getRealEstateDetails($realEstateId: ObjectID!) @api(name: realEstate) {
    getRealEstateDetails(realEstateId: $realEstateId) {
      ...RealEstateAllFieldsForPublish
    }
  }
  ${REAL_ESTATE_ALL_FIELDS_FRAGMENT_FOR_PUBLISH}
`;

export const REAL_ESTATE_ON_MAP_V2 = gql`
  query (
    $phone: String
    $bottomLeft: MapDataInput!
    $upperRight: MapDataInput!
    $circle: CircleInput
    $polygonPoints: [MapDataInput] = []
    $searchString: String
    $typeOfDemand: String
    $realEstateType: [String]
    $utilities: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $bedRoomArea: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $blockCount: FromTo
    $alleyWidth: FromTo
    $typeOfRealEstate: [String]
    $hasAlley: Boolean
    $tradingStatus: TradingStatus
    $postStatus: PostStatusType
  ) @api(name: realEstate) {
    realEstateOnMapV3(
      filter: {
        phone: $phone
        circle: $circle
        polygonPoints: $polygonPoints
        bottomLeft: $bottomLeft
        upperRight: $upperRight
        searchString: $searchString
        typeOfDemand: $typeOfDemand
        realEstateType: $realEstateType
        typeOfRealEstate: $typeOfRealEstate
        province: $province
        district: $district
        ward: $ward
        street: $street
        price: $price
        area: $area
        length: $length
        width: $width
        rear: $rear
        floorCount: $floorCount
        bedroomCount: $bedroomCount
        toiletCount: $toiletCount
        bedRoomArea: $bedRoomArea
        floorPosition: $floorPosition
        roomCount: $roomCount
        blockCount: $blockCount
        alleyWidth: $alleyWidth
        utilities: $utilities
        hasAlley: $hasAlley
        tradingStatus: $tradingStatus
        postStatus: $postStatus
      }
    ) {
      id
      count
      lat
      long
      bottomLeft {
        type
        coordinates
      }
      upperRight {
        type
        coordinates
      }
    }
  }
`;

export const REAL_ESTATE_LIST_V2 = gql`
  query (
    $phone: String
    $page: Int
    $limit: Int
    $bottomLeft: MapDataInput
    $upperRight: MapDataInput
    $polygonPoints: [MapDataInput] = []
    $circle: CircleInput
    $searchString: String
    $typeOfDemand: String
    $realEstateType: [String]
    $province: String
    $district: String
    $ward: String
    $street: String
    $price: FromTo
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $bedRoomArea: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $blockCount: FromTo
    $alleyWidth: FromTo
    $sort: String
    $typeOfRealEstate: [String]
  ) @api(name: realEstate) {
    realEstateListV2(
      page: $page
      limit: $limit
      filter: {
        phone: $phone
        polygonPoints: $polygonPoints
        circle: $circle
        bottomLeft: $bottomLeft
        upperRight: $upperRight
        searchString: $searchString
        typeOfDemand: $typeOfDemand
        realEstateType: $realEstateType
        typeOfRealEstate: $typeOfRealEstate
        province: $province
        district: $district
        ward: $ward
        street: $street
        price: $price
        area: $area
        length: $length
        width: $width
        rear: $rear
        floorCount: $floorCount
        bedroomCount: $bedroomCount
        toiletCount: $toiletCount
        bedRoomArea: $bedRoomArea
        floorPosition: $floorPosition
        roomCount: $roomCount
        blockCount: $blockCount
        sort: $sort
        alleyWidth: $alleyWidth
      }
    ) {
      total
      hasNextPage
      page
      items {
        ... on RealEstate {
          map {
            type
            coordinates
          }
          ...RealEstateAllFieldsV2
        }
        ... on RealEstateProject {
          map {
            type
            coordinates
          }
          ...RealEstateProjectAllFieldsV2
        }
      }
    }
  }
  ${REAL_ESTATE_ALL_FIELDS_V2_FRAGMENT}
  ${REAL_ESTATE_PROJECT_ALL_FIELDS_V2_FRAGMENT}
`;

export const GET_USER_SAVED_REAL_ESTATE = (field = []) => gql`
  query getUserSavedRealEstate($realEstateType: [String], $mediaTypes: [String], $tradingStatus: TradingStatus, $postType: [Int], $sort: JSONObject, $collection: String, $page: Int, $limit: Int, $demandType: String) @api(name: realEstate) {
    getUserSavedRealEstate(realEstateType: $realEstateType, mediaTypes: $mediaTypes, tradingStatus: $tradingStatus, postType: $postType, sort: $sort, collection: $collection, page: $page, limit: $limit, demandType: $demandType) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        type
        note
        item {
          ... on RealEstate {
            id
            ${field.join(' ')}
          }
        }
      }
    }
  }
  ${field.find((e) => e.includes('FileAllFields2')) ? MEDIA_UNION : ''}
`;

export const GET_USER_SAVED_NEEDED_REAL_ESTATE = (field = []) => gql`
  query getUserSavedNeededRealEstate($demandType: String, $collection: String, $realEstateType: [String], $tradingStatus: [TradingStatus], $page: Int, $limit: Int, $sort: JSONObject, $postType: [Int]) @api(name: realEstate){
    getUserSavedNeededRealEstate(demandType: $demandType, collection: $collection, realEstateType: $realEstateType, tradingStatus: $tradingStatus, page: $page, limit: $limit, sort: $sort, postType: $postType) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        type
        note
        item {
          ... on NeededRealEstate {
            id
            ${field.join(' ')}
          }
        }
      }
    } 
  }
  ${field.find((e) => e.includes('FileAllFields2')) ? MEDIA_UNION : ''}
`;

export const GET_NEED_REAL_ESTATE_DETAILS = gql`
  query getNeededRealEstateDetails($realEstateId: ObjectID)
  @api(name: realEstate) {
    getNeededRealEstateDetails(realEstateId: $realEstateId) {
      id
      demandEstate
      type
      province {
        id
        name
      }
      district {
        id
        name
      }
      fullAddress
      isNeedToBuy
      isIncognito
      isNewly
      ward {
        id
        name
      }
      creator {
        id
        avatar {
          ... on FileWithFullUrls {
            originalUrl
          }
        }
      }
      street {
        id
        name
      }
      hasAlley
      timeForRent {
        from
        to
      }
      width {
        from
        to
      }
      constructionArea {
        from
        to
      }
      length {
        from
        to
      }
      rear {
        from
        to
      }
      area {
        from
        to
      }
      price {
        from
        to
      }
      clearanceArea {
        from
        to
      }
      villaView
      hotelStar
      investor
      floorCount {
        from
        to
      }
      floorPosition {
        from
        to
      }
      bedroomCount {
        from
        to
      }
      toiletCount {
        from
        to
      }
      saveItem {
        id
        note
        belongsToCollection {
          id
        }
      }
      projectName
      blockName
      buildingType
      factoryFunctions
      industryTrade
      hasMezzanine
      postTitle
      postContent
      privateNote
      direction
      contactName
      contactPhones
      contactEmail
      postType
      startShowTime
      endShowTime
      tradingStatus
      postStatus
      legals
      location {
        type
        coordinates
      }
      fixedUtilities
      utilities
      localUtilities
      equipmentsAndServices
      realEstateId
      demandEstate
      createdAt
      approvalStatus
      approvalReason
    }
  }
`;
